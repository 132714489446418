import { createSlice } from "@reduxjs/toolkit"
import clientService from "./services/service"

export const cartInitialState = {
  loader: false,
  cartItems: [],
  checkoutResponse: {},
  otpResponse: {},
  verifyOtpResponse: {},
  userInfo: {
    first_name: "Test",
    last_name: "Text",
    phone: "9724757428",
    email: "mitul@gmail.com",
    bod: "06/09/1987",
    flat_no: "12 A",
    street: "Text Street",
    additional: "Text additional",
    landmark: "SIDDHIVINAYAK TEMPLE",
    delivery_option: "Current Delivery",
    order_date: "",
    pincode: "388001",
  },
}
export const cartSlice = createSlice({
  name: "cart",
  initialState: cartInitialState,
  reducers: {
    setCartItems: (state, action) => {
      state.cartItems = action.payload
      state.loader = false
    },
    setCheckoutResponse: (state, action) => {
      state.checkoutResponse = action.payload
      state.loader = false
    },
    setOtpResponse: (state, action) => {
      state.otpResponse = action.payload
      state.loader = false
    },
    setVerifyOtpResponse: (state, action) => {
      state.verifyOtpResponse = action.payload
      state.loader = false
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
  },
})

export const checkoutAPI = (data) => async (dispatch) => {
  dispatch(setCheckoutResponse({}))
  dispatch(setLoader(true))
  const resp = await clientService.post("checkout", data)
  dispatch(setCheckoutResponse(resp.data))
  // dispatch(setLoader(false))
}

export const sendOtpAPI = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const resp = await clientService.post("sendotp", data)
  dispatch(setOtpResponse(resp.data))
  // dispatch(setLoader(false))
}

export const verifyOtpAPI = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const resp = await clientService.post("verifyotp", data)
  dispatch(setVerifyOtpResponse(resp.data))
  // dispatch(setLoader(false))
}

export const { setCartItems, setCheckoutResponse, setOtpResponse, setVerifyOtpResponse, setLoader } =
  cartSlice.actions

export const cartState = (state) => {
  return state.cart
}

export default cartSlice.reducer
