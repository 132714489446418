import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import parse from "html-react-parser"
import { CMSState, getRefundPolicy } from "../slices/CmSlice"
import SideStick from "./SideStick"

const RefundPolicy = () => {
  const dispatch = useDispatch()
  const { refundPolicy } = useSelector(CMSState)
  useEffect(() => {
    dispatch(getRefundPolicy())
  }, [dispatch])

  return (
    <>
      <SideStick />
      <div>
        <div className="banner">
          <p className="c1">Refund & Cancellation Policy</p>
        </div>
        {refundPolicy.content ? (
          <p className="normal2">{parse(refundPolicy.content)}</p>
        ) : null}
      </div>
    </>
  )
}
export default RefundPolicy
