import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCartPlus } from "@fortawesome/free-solid-svg-icons"

import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { cartState } from "../slices/CartSlice"

const Header = () => {
  const { cartItems } = useSelector(cartState)
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src="/assets/images/logo.png" className="logo" alt="Poptos" />
        </Navbar.Brand>
        <div className="mob-cart">
          <Link className="cart-icon mob-cart a-decor" to="/cart">
            <FontAwesomeIcon icon={faCartPlus} />
            <sup className="cart-link">{cartItems.length}</sup>
          </Link>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          className="main-menu justify-space-between"
          id="basic-navbar-nav"
        >
          <Nav className="">
            <Link className="nav-link" to="/">
              Home
            </Link>
            <Link className="nav-link" to="/menu">
              Menu
            </Link>
            <Link className="nav-link" to="/our-outlets">
              Our Outlets
            </Link>
          </Nav>
          <Nav className="nav-btn">
            <Link className="nav-link mob-view" to="/online-order">
              Order Online
            </Link>
            {/* <Link className='nav-link' to="/login">Login</Link> */}
            <Link className="cart-icon a-decor" to="/cart">
              <FontAwesomeIcon icon={faCartPlus} />
              <sup className="cart-link margin-2 ">{cartItems.length}</sup>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
