
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage'
import menuReducer from './slices/MenuSlice';
import outletReducer from "./slices/OutletSlice";
import CMSReducer from "./slices/CmSlice";
import CartReducer from "./slices/CartSlice";


const rootReducer = combineReducers({
    menu: menuReducer,
    outlets: outletReducer,
    cms: CMSReducer,
    cart:CartReducer
})


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)


