import { createSlice } from "@reduxjs/toolkit";
import clientService from "./services/service";

export const menuInitialState = {
    loader: false,
    menuList: {
        categories : []
    },
    toppings : [],
    extra_items : [],
    itemList: {
        product : []
    }
}
export const menuSlice = createSlice({
    name: "menu",
    initialState: menuInitialState,
    reducers: {
        setMenuResponse: (state, action) => {
            if(action.payload?.categories) {
                state.menuList = action.payload;
            }
            state.loader = false;
        },
        setItemDetailResponse: (state, action) =>{
            if(action.payload?.product) {
                state.itemList = action.payload;
            }
            state.loader = false;
        },
        setToppingLoader : (state, action) => {
            state.loader = action.payload;
        },
        setToppingResponse: (state, action) => {
            
            if(action.payload.toppings) {
                state.toppings = action.payload.toppings
            }else{
                state.toppings = []
            }
            if(action.payload.extra_items){
                state.extra_items = action.payload.extra_items
            }else{
                state.extra_items = []
            }
            state.loader = false;
        }

    }
})

export const getMenu = () => async (dispatch) =>{
    dispatch(setToppingLoader(true));
    const response = await clientService.get("menu");
    if(response.data){
        dispatch(setMenuResponse(response.data.data)) 
    }else{
       // dispatch(setMenuResponse(response.message)) 
    }
    
}
export const postItemDetail = (data) => async (dispatch) =>{
    dispatch(setToppingLoader(true));
    const response = await clientService.post("item/detail", data);
    if(response.data){
        dispatch(setItemDetailResponse(response.data.data))
    }else{

    }
}
export const getToppings = (data) => async (dispatch) =>{
    dispatch(setToppingLoader(true));
    const response = await clientService.post("item/topping", data);
    if(response.data){
        dispatch(setToppingResponse(response.data.data))
    }
    
}

export const { setMenuResponse, setItemDetailResponse, setToppingLoader, setToppingResponse } = menuSlice.actions

export const menuState = (state) => {
    return state.menu
}

export default menuSlice.reducer;