import { createSlice } from "@reduxjs/toolkit";
import clientService from "./services/service";



export const authInitialState = {
  loader: false,
  userData: {},
  isLoggedIn: false,
  errors: [],
  successMessage: [],
  isVerified: false,
  isLoggedInFail: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
      if (action.payload) {
        state.errors = [];
        state.successMessage = [];
      }
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
      state.loader = false;
      state.errors = [];
      state.isLoggedIn = true;
    },
    setErrors: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.loader = false;
      state.successMessage = [action.payload.data];
    },
    resetMessage: (state, action) => {
      state.successMessage = [];
      state.errors = [];
    },
    setLogginFail: (state, action) => {
      state.isLoggedInFail = true;
      state.isLoggedIn = false;
      state.loader = false;
    },
    logout: (state, action) => {
      state.loader = false;
      state.userData = {};
      state.isLoggedIn = false;
      state.errors = [];
      state.successMessage = [];
      state.isLoggedInFail = false;
    },
    setLoginResponse: (state, action) =>{
      if(action.payload?.email) {
          state.userList = action.payload;
      }
  },
    setVerified:(state, action) => {
      state.loader = false;
      state.isVerified = true
    }
  },
});

export const login = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("/login", data);
  if (response.status === 200) {
    localStorage.setItem("token", response.data.data.token)
  } else {
    dispatch(setErrors(response));
  }
};

export const postLogin = (data) => async (dispatch) =>{
  const response = await clientService.post("login", data);
  if(response.data){
      dispatch(setLoginResponse(response.data.data))
  }else{

  }
}
export const register = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("auth/registration", data);
  if (response.status === 201) {
    dispatch(setUserData(response.data));
  } else {
    dispatch(setErrors(response));
  }
};


export const accountVerify = (token) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.get("auth/registration/email-verify/" + token);
  if (response.status >= 200 && response.status <= 299) {
    dispatch(setVerified(true));
  }
};

export const { setLoader, setUserData,setVerified, setLoginResponse, setErrors, logout, setSuccessMessage, resetMessage, setLogginFail } = authSlice.actions;

export const authState = (state) => {
  return state.auth;
};

export default authSlice.reducer;
