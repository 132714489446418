import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { FiAlertCircle } from "react-icons/fi"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import {
  cartState,
  checkoutAPI,
  sendOtpAPI,
  setCartItems,
  setCheckoutResponse,
  setVerifyOtpResponse,
  verifyOtpAPI,
} from "../slices/CartSlice"
import PaytmButton from "../components/PaytmButton"
import Loader from "../components/Loader"
import { useNavigate } from "react-router"
import { getMenu } from "../slices/MenuSlice"
import moment from "moment"
import { useAlert } from "react-alert"
import SideStick from "./SideStick"

const Checkout = () => {
  const { cartItems, loader, checkoutResponse, verifyOtpResponse } =
    useSelector(cartState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const alert = useAlert()
  const [total, setTotal] = useState(0)
  const [otp, setOtp] = useState("")
  const [successFlag, setSuccessFlag] = useState("0")
  const [data, setData] = useState([])
  const [gst, setGst] = useState(0)
  const [finalTotal, setFinalTotal] = useState(0)
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  const [random1] = useState(random(0, 10))
  const [random2] = useState(random(0, 10))
  const [paymentBox, showPaymentBox] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showOTPModal, setShowOTPModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleOTPClose = () => setShowOTPModal(false)

  useEffect(() => {
    const locationInfo = localStorage.getItem("data")
    if (!locationInfo) {
      navigate("/online-order")
    }
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      flat_no: "",
      street: "",
      additional: "",
      landmark: "",
      delivery_option: "",
      pincode: "",
    },
  })
  useEffect(() => {
    if (checkoutResponse?.statusCode === "200") {
      if (checkoutResponse.data?.id) {
        setData(checkoutResponse.data)
        window.open(checkoutResponse.data.redirectUrl, '_self')
        // showPaymentBox(true)
      }
      if (paymentBox === false) {
        dispatch(setCheckoutResponse({}))
      }

      if (
        checkoutResponse.data &&
        checkoutResponse.data.payment_method === "cod"
      ) {
        navigate("/order/success")
        dispatch(setCheckoutResponse({}))
      }
      // dispatch(setCartItems([]))
    }
  }, [loader, checkoutResponse])

  const onSubmit = (data) => {
    const locationInfo = localStorage.getItem("data")
    const dataInfo = JSON.parse(locationInfo)
    const checkout = { ...data }
    checkout.cart = []
    checkout.location = dataInfo?.location !== "" ? dataInfo.location : "Anand"
    checkout.valid_otp = "1234"
    checkout.order_date =
      checkout.delivery_type !== "0"
        ? checkout.order_date
        : moment().format("DD-MM-YYYY hh:mm:ss")
    checkout.delivery_type = checkout.delivery_type
    checkout.delivery_option =
      dataInfo?.delivery_option !== ""
        ? dataInfo.delivery_option
        : "Home Delivery"
    checkout.payment_method = checkout.payment_method

    for (var i = 0; i < cartItems.length; i++) {
      const variation = []
      if (cartItems[i].crust) {
        variation.push(cartItems[i].crust)
      }

      if (cartItems[i].size) {
        variation.push(cartItems[i].size)
      }
      checkout.cart.push({
        product_id: cartItems[i].product.id,
        product_name: cartItems[i].product.product_name,
        qty: cartItems[i].qty,
        price: cartItems[i].price,
        toppings: cartItems[i].selectedToppings.join(","),
        variation: variation.join(","),
      })
    }
    if (checkout.payment_method === "cod") {
      const randomTotal = random1 + random2
      if (checkout.cod_text != randomTotal) {
        alert.error("Please enter correct COD verification text")
        return
      }
    }
    dispatch(checkoutAPI(checkout))
  }

  useEffect(() => {
    var totalPrice = 0
    for (var i = 0; i < cartItems.length; i++) {
      totalPrice += cartItems[i].price * cartItems[i].qty
    }
    if (totalPrice > 800) {
      setValue("payment_method", "bank")
    }
    var cgst = totalPrice * 0.025
    setGst(Number(cgst.toFixed(2)))
    setTotal(totalPrice)
    setFinalTotal(Math.round(totalPrice + cgst + cgst))

  }, [cartItems])

  useEffect(() => {
    if (verifyOtpResponse) {
      if (verifyOtpResponse.statusCode === "200") {
        alert.success(verifyOtpResponse.message)
        dispatch(setVerifyOtpResponse({}))
        setShowOTPModal(false)
        setSuccessFlag("1")
      }
    }
  }, [verifyOtpResponse])

  const otpResp = (data) => {
    setShowOTPModal(true)
    const phoneData = {
      phone: data,
    }
    dispatch(sendOtpAPI(phoneData))
  }

  const otpVerify = (data) => {
    dispatch(verifyOtpAPI(data))
  }

  return (
    <>
      <SideStick />
      {loader && <Loader />}
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="cart mb-3">
            <p className="c1">Checkout</p>
          </div>
          <Container>
            <Row>
              <Col md={8}>
                <Card className="card mb-5">
                  <Row>
                    <Col>
                      <h4 className="contact">Contact Information</h4>
                      <Row>
                        <Col className="col-md-6">
                          <input
                            className="f_name"
                            {...register("first_name", {
                              required: true,
                              maxLength: 20,
                              pattern: /^[A-Za-z]+$/i,
                            })}
                            type="text"
                            placeholder="First Name"
                          />
                          {errors?.first_name?.type === "required" && (
                            <p className="pp">Please enter first Name</p>
                          )}
                          {errors?.first_name?.type === "maxLength" && (
                            <p className="pp">
                              First name cannot exceed 20 characters
                            </p>
                          )}
                          {errors?.first_name?.type === "pattern" && (
                            <p className="pp">Alphabetical characters only</p>
                          )}
                        </Col>
                        <Col className="col-md-6">
                          <input
                            className="l_name"
                            {...register("last_name", {
                              required: true,
                              maxLength: 20,
                              pattern: /^[A-Za-z]+$/i,
                            })}
                            type="text"
                            placeholder="Last Name"
                          />
                          {errors?.last_name?.type === "required" && (
                            <p className="pp">Please enter last name</p>
                          )}
                          {errors?.last_name?.type === "maxLength" && (
                            <p className="pp">
                              Last Name cannot exceed 20 characters
                            </p>
                          )}
                          {errors?.last_name?.type === "pattern" && (
                            <p className="pp">Alphabetical characters only</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input
                            className="f_name"
                            {...register("phone", {
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                            })}
                            type="tel"
                            placeholder="Mobile Number"
                          />
                          {errors?.phone?.type === "required" && (
                            <p className="pp">Please enter Mobile number.</p>
                          )}
                        </Col>
                        <Col>
                          <button
                            type="button"
                            className="mobile"
                            onClick={() => {
                              if (getValues("phone")) {
                                otpResp(watch("phone"))
                              } else {
                                setShowModal(true)
                              }
                            }}
                          >
                            Verify
                          </button>
                          {errors?.phone?.type === "required" && (
                            <p className="pp">
                              please validate your mobile no.
                            </p>
                          )}
                        </Col>
                      </Row>
                      <input
                        className="eml"
                        {...register("email", { required: true })}
                        type="text"
                        placeholder="Email Id"
                      />
                      {errors?.email?.type === "required" && (
                        <p className="pp">Please enter Email id.</p>
                      )}
                      <DatePicker
                        {...register("bod", { required: false })}
                        className="eml"
                        placeholderText="Birth Date"
                        selected={getValues("bod")}
                        onChange={(date) => {
                          setValue("bod", date, { shouldValidate: true })
                        }}
                        maxDate={moment().toDate()}
                      />
                      {errors?.bod?.type === "required" && (
                        <p className="pp">
                          The date of birth field is required.
                        </p>
                      )}
                      <h4 className="del-add">Delivery Address</h4>
                      <input
                        className="eml"
                        {...register("flat_no", { required: true })}
                        type="text"
                        placeholder="Flat No./Block No.*"
                      />
                      {errors?.flat_no?.type === "required" && (
                        <p className="pp">Please enter flat no.</p>
                      )}
                      <input
                        className="eml"
                        {...register("street", { required: true })}
                        type="text"
                        placeholder="Street/Building Name *"
                      />
                      {errors?.street?.type === "required" && (
                        <p className="pp">Please enter street address.</p>
                      )}
                      <input
                        className="eml"
                        {...register("additional", { required: true })}
                        type="text"
                        placeholder="Additional Details"
                      />
                      {errors?.additional?.type === "required" && (
                        <p className="pp">
                          Please enter additional information.
                        </p>
                      )}
                      <select
                        className="eml"
                        {...register("landmark", { required: true })}
                        name="landmark"
                        id="landmark"
                        placeholder="Select Landmark..."
                      >
                        <option value="">Select Landmark...</option>
                        <option value="BAKROL ROAD ANAND">
                          BAKROL ROAD ANAND
                        </option>
                        <option value="ANAND VIDHYANAGAR ROAD">
                          ANAND VIDHYANAGAR ROAD
                        </option>
                        <option value="BAKROL VADTAL ROAD">
                          BAKROL VADTAL ROAD
                        </option>
                        <option value="BSNL EXCHANGE">BSNL EXCHANGE</option>
                        <option value="SIDDHIVINAYAK TEMPLE">
                          SIDDHIVINAYAK TEMPLE
                        </option>
                        <option value="TIRUPATI PETROL PUMP">
                          TIRUPATI PETROL PUMP
                        </option>
                        <option value="BAPS CIRCLE">BAPS CIRCLE</option>
                        <option value="BAKROL ROAD">BAKROL ROAD</option>
                        <option value="KARAMSAT ROAD ANAND">
                          KARAMSAD ROAD ANAND
                        </option>
                        <option value="CHARUTAR VIDYAMANDAL OFFICE">
                          CHARUTAR VIDYAMANDAL OFFICE
                        </option>
                        <option value="BHAIKAKA CIRCLE">BHAIKAKA CIRCLE</option>
                        <option value="GIDC INDUSTRIAL ESTATE">
                          GIDC INDUSTRIAL ESTATE
                        </option>
                        <option value="ITI KARAMSAD">ITI KARAMSAD</option>
                        <option value="NANA BAZAAR">NANA BAZAAR</option>
                        <option value="PUROHIT DINING HALL">
                          PUROHIT DINING HALL
                        </option>
                        <option value="RAMDEVPEER TEMPLE">
                          RAMDEVPEER TEMPLE
                        </option>
                        <option value="HOME SCIENCE COLLEGE">
                          HOME SCIENCE COLLEGE
                        </option>
                        <option value=" SARDAR PATEL UNIVERSITY">
                          {" "}
                          SARDAR PATEL UNIVERSITY
                        </option>
                        <option value="TIME CINEMA">TIME CINEMA</option>
                        <option value="DIVINE DINING HALL RESTAURANT">
                          DIVINE DINING HALL RESTAURANT
                        </option>
                        <option value="VIDHYANAGAR POST OFFICE">
                          VIDHYANAGAR POST OFFICE
                        </option>
                        <option value="UNIVERSITY CIRCLE">
                          UNIVERSITY CIRCLE
                        </option>
                      </select>
                      {errors?.landmark?.type === "required" && (
                        <p className="pp">please select landmark.</p>
                      )}
                      <select
                        {...register("delivery_type", { required: true })}
                        className="eml"
                        name="delivery_type"
                        id="delivery_type"
                        placeholder="Select Delivery Option"
                        onChange={(e) => {
                          setValue("delivery_type", e.target.value)
                        }}
                        selected={getValues("delivery_type")}
                      >
                        <option value="">Select Delivery Option</option>
                        <option value="0">Current Delivery</option>
                        <option value="1">Advance Delivery</option>
                      </select>
                      {errors?.delivery_type?.type === "required" && (
                        <p className="pp">Please select delivery option.</p>
                      )}
                      {watch("delivery_type") === "1" ? (
                        <DatePicker
                          {...register("order_date", { required: false })}
                          className="eml"
                          selected={getValues("order_date")}
                          onChange={(date) => {
                            setValue("order_date", date, {
                              shouldValidate: true,
                            })
                          }}
                          placeholderText="Order Date"
                          showTimeSelect
                          timeFormat="HH:mm"
                          injectTimes={[]}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          minDate={moment().toDate()}
                        />
                      ) : null}
                      {errors?.order_date?.type === "required" && (
                        <p className="pp">The field is required.</p>
                      )}
                      <input
                        className="eml"
                        {...register("pincode", {
                          required: true,
                          maxLength: 6,
                        })}
                        type="text"
                        placeholder="Pincode"
                      />
                      {errors?.pincode?.type === "required" && (
                        <p className="pp">Please enter Pin Code.</p>
                      )}
                      <div className="font">
                        NOTE:Not Finding Nearest Place? Call Us On: +91 95862
                        29797
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="card">
                  <div className="card-2-title">Cart Totals</div>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col>
                          <span className="subtotal">Subtotal</span>
                        </Col>
                        <Col className="text-right">₹{total.toFixed(2)}</Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="subtotal">CGST @ 2.5%</span>
                        </Col>
                        <Col className="text-right">₹{gst.toFixed(2)}</Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="subtotal">SGST @ 2.5%</span>
                        </Col>
                        <Col className="text-right">₹{gst.toFixed(2)}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="line-black" />
                  <Row>
                    <Col>
                      <span className="subtotal">Final Total</span>
                    </Col>
                    <Col className="text-right">₹{finalTotal.toFixed(2)}</Col>
                  </Row>
                </Card>
                <Card className="card mt-5 mb-5">
                  <h4 className="contact">Select Payment Method</h4>

                  {total < 800 ? (
                    <>
                      <input
                        {...register("payment_method", { required: true })}
                        type="radio"
                        name="payment_method"
                        value="cod"
                        className="radio-2"
                      />
                      <span className="cod">Cash on Delivery</span>
                      <div className="ltr">
                        {random1} + {random2} =
                      </div>
                      <input
                        {...register("cod_text")}
                        className="num"
                        type="text"
                        name="cod_text"
                      />
                    </>
                  ) : null}

                  <input
                    {...register("payment_method", { required: true })}
                    type="radio"
                    name="payment_method"
                    value="bank"
                    className="radio-2"
                  />
                  <span className="cod">Pay Online</span>
                  <p className="axis">With Axis Bank</p>
                  <label className="">
                    <input
                      id="check_terms"
                      className="rado"
                      name="check_terms"
                      type="checkbox"
                    />
                    <span className="fon">
                      I’ve read and accept the
                      <a href="/terms_and_condition" className="terms">
                        {" "}
                        Terms &amp; Conditions *
                      </a>
                    </span>
                  </label>
                  {successFlag === "1" ? (
                    <button className="updatecart" type="submit">
                      Place order
                    </button>
                  ) : (
                    <button className="updatecart" type="submit" disabled>
                      Place order
                    </button>
                  )}

                  {paymentBox ? <PaytmButton data={data} /> : null}
                </Card>
              </Col>
            </Row>
          </Container>
        </form>

        <Modal className="modal" show={showModal} onHide={handleClose}>
          <FiAlertCircle className="alert-circle" />
          <h5 className="mob">Please enter your mobile number.</h5>
          <Button className="ok" variant="secondary" onClick={handleClose}>
            OK
          </Button>
        </Modal>

        <Modal
          show={showOTPModal}
          onHide={handleOTPClose}
          style={{ display: "flex", textAlign: "center" }}
        >
          <div className="modal-otp" style={{ padding: 50 }}>
            <p
              style={{
                color: "white",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <strong>
                Please enter the one time password to verify your mobile number.
              </strong>
            </p>
            <span
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
                textAlign: "center",
              }}
            >
              code has been send to ******{getValues("phone").slice(6)}
            </span>
            <input
              className="form-control"
              name="valid_otp"
              id="valid_otp"
              type="text"
              placeholder="Enter OTP"
              onChange={(e) => {
                setOtp(e.target.value)
              }}
              style={{ marginTop: 20, width: "60%", margin: "auto" }}
            />
            <Row>
              <Col style={{ marginTop: 20 }}>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    const data = {
                      phone: getValues("phone"),
                      otp: otp,
                    }
                    otpVerify(data)
                  }}
                  style={{
                    backgroundColor: "#ffcb08",
                    color: "black",
                    borderRadius: 30,
                    borderWidth: 0,
                    fontWeight: "bold",
                    width: "25%",
                    height: "110%",
                  }}
                >
                  SUBMIT
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleOTPClose}
                  style={{
                    borderRadius: 30,
                    marginLeft: 10,
                    width: "25%",
                    height: "110%",
                  }}
                >
                  CLOSE
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </>
  )
}
export default Checkout
