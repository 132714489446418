import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import ReactDOM from "react-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
// import { Link } from "react-router-dom";
import { postLogin, authState, setLoginResponse } from "../slices/AuthSlice"

const Login = () => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
//   const { userList } = useSelector(authState)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const onSubmit = (data) => {
    const loginData = JSON.stringify(data)
    alert(loginData)
    dispatch(postLogin(JSON.parse(loginData)))
  }

  return (
    <>
      <div className="cart mb-3">
        <p className="c1">Welcome To Poptos</p>
      </div>
      <Container>
        <Row>
          <Col md={8}>
            <Card className="card4 mb-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <h4 className="carddd">Login</h4>
                </div>
                <Row>
                  <Col lg={8} md={8}>
                    <div>
                      <input
                        className="login-email"
                        name="email"
                        {...register("email", { required: true })}
                        type="email"
                        placeholder="Email address"
                      />
                      {errors?.email?.type === "required" && (
                        <p className="pp">Please enter Email id.</p>
                      )}
                    </div>
                    <div>
                      <input
                        className="login-password"
                        name="password"
                        {...register("password", { required: true })}
                        type="password"
                        placeholder="Password"
                      />
                      {errors?.password?.type === "required" && (
                        <p className="pp">Please enter Password</p>
                      )}
                    </div>
                    <div className="rem">
                      <input className="checkbox" type="checkbox" />
                      <span className="remember">Remember me</span>
                    </div>
                    <div>
                      <button className="login-btn" type="submit">
                        Login
                      </button>
                    </div>
                    <div>
                      <a href="/forgot-password" className="lost-pwd">
                        Lost your password?
                      </a>
                    </div>
                    <div className="new-user">
                      New User?{" "}
                      <a href="/sign-up" className="sign-up">
                        Sign Up Now
                      </a>
                    </div>
                  </Col>
                </Row>
                {/* <Link to="/forgot-password" className="lost-pwd">Lost your password?</Link> */}
              </form>
            </Card>
          </Col>
          <Col>
            <img
              className="gif"
              src="https://www.poptos.com/assets/user/images/popman-gif-log-in.gif"
              alt="gif"
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
// const rootElement = document.getElementById("root")
// ReactDOM.render(<Login />, rootElement)
export default Login
