import { useEffect, useState } from "react"
import { Row, Col, Container, Nav, Navbar, Offcanvas } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ItemDetail from "../components/ItemDetail"
import { getMenu, menuState, setMenuResponse } from "../slices/MenuSlice"
import StickyBox from "react-sticky-box"
import { useNavigate } from "react-router-dom"
import SideStick from "./SideStick"

const Menu = ({ name, ...props }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { menuList } = useSelector(menuState)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [currentItem, setCurrentItem] = useState({})

  useEffect(() => {
    const locationInfo = localStorage.getItem("data")
    if (!locationInfo) {
      navigate("/online-order")
    } else {
      dispatch(getMenu())
    }
  }, [])

  const itemDetail = (value) => {
    setCurrentItem(value)
    setShow(true)
  }
  return (
    <>
      <SideStick />
      <Container>
        <Row>
          <Col md={2} className="left-menu mt-5 mb-5">
            <StickyBox offsetTop={20} offsetBottom={20}>
              <Navbar collapseOnSelect expand="lg">
                <Container>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                      {menuList?.categories.map((item) => {
                        return (
                          <Nav.Link href={"#item" + item.id}>
                            {item.category_name}
                          </Nav.Link>
                        )
                      })}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </StickyBox>
          </Col>
          <Col className="main-container">
            {menuList?.categories.map((category, index) => {
              return (
                <>
                  <h2 className="mt-5" id={"item" + category.id}>
                    {category.category_name}
                  </h2>
                  <Row>
                    {category.items.map((item, iIndex) => {
                      const image =
                        "https://www.poptos.com/assets/image/upload/" +
                        item.image
                      return (
                        <Col md={4} className={"mb-3"}>
                          <div className="menu-item-box">
                            <img className="pop-pizza" src={image} alt="" />

                            <div className="pizzaname">{item.product_name}</div>
                            <button
                              className="add"
                              onClick={() => itemDetail(item)}
                            >
                              Add To Cart
                            </button>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </>
              )
            })}
          </Col>
        </Row>
        <Offcanvas
          className="sidecart"
          show={show}
          placement={"end"}
          onHide={handleClose}
          {...props}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{currentItem?.product_name}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ItemDetail handleClose={handleClose} product={currentItem} />
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </>
  )
}

export default Menu
