import { useEffect } from "react"
import { useSelector } from "react-redux"
import { cartState } from "../slices/CartSlice"
import Loader from "./Loader"

const PaytmButton = ({ data }) => {
  const { loader } = useSelector(cartState)
  useEffect(() => {
    initialize()
  }, [])
  const initialize = () => {
    var config = {
      root: "",
      flow: "DEFAULT",
      data: {
        orderId: data?.order_unique_id /* update order id */,
        token: data?.txnToken /* update token value */,
        tokenType: "TXN_TOKEN",
        amount: data?.total_amt /* update amount */,
      },
      handler: {
        notifyMerchant: function (eventName, data) {
          console.log("notifyMerchant handler function called")
          console.log("eventName => ", eventName)
          console.log("data => ", data)
        },
      },
    }
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          // after successfully updating configuration, invoke JS Checkout
          window.Paytm.CheckoutJS.invoke()
        })
        .catch(function onError(error) {
          console.log("error => ", error)
        })
    }
  }
  return (
    <>
      {loader && <Loader />}
      <div>Payment</div>
    </>
  )
}

export default PaytmButton
