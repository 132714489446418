import { Card, Col, Container, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import SideStick from "./SideStick"

const Feedback = (customerData) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const onSubmit = (data) => {
    alert(JSON.stringify(data))
  }
  return (
    <>
      <SideStick />
      <div className="cart">
        <p className="c1">Feedback</p>
      </div>
      <Container>
        <Row>
          <Col md={8}>
            <Card className="feed mb-5 mt-5">
              <div>
                <h2 className="feedback">Feedback</h2>
                <Row>
                  <Col lg={8} md={8}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <input
                        type="text"
                        className="feedbacks"
                        {...register("firstName", {
                          required: true,
                          maxLength: 20,
                          pattern: /^[A-Za-z]+$/i,
                        })}
                        name="name"
                        id="name"
                        placeholder="Your Name"
                      />
                      {errors?.firstName?.type === "required" && (
                        <p className="pp">Please enter your Name</p>
                      )}
                      {errors?.firstName?.type === "maxLength" && (
                        <p className="pp">
                          First name cannot exceed 20 characters
                        </p>
                      )}
                      {errors?.firstName?.type === "pattern" && (
                        <p className="pp">Alphabetical characters only</p>
                      )}
                      <input
                        type="email"
                        {...register("email", { required: true })}
                        className="feedbacks"
                        name="email"
                        id="email"
                        placeholder="Email Id"
                      />{" "}
                      {errors?.email?.type === "required" && (
                        <p className="pp">Please enter Email id.</p>
                      )}
                      <input
                        type="tel"
                        {...register("phone", {
                          required: true,
                          maxLength: 11,
                        })}
                        className="feedbacks"
                        name="phone"
                        id="phone"
                        placeholder="Mobile No."
                      />
                      {errors?.phone?.type === "required" && (
                        <p className="pp">Please enter Mobile number.</p>
                      )}
                      <textarea
                        name="feedback"
                        {...register("feedback", {
                          required: true,
                          maxLength: 110,
                        })}
                        className="feedbacks-text"
                        rows="10"
                        id="feedback"
                        placeholder="Your Comment"
                      />
                      {errors?.phone?.type === "required" && (
                        <p className="pp">Please Enter your Feedback.</p>
                      )}
                      <div className="">
                        <select
                          className="eml"
                          {...register("order_location", { required: true })}
                          name="order_location"
                          id="order_location"
                          placeholder="Enter Area/ Location"
                        >
                          <option value="">Enter Area/ Location</option>
                          <option value="Anand">Anand</option>
                          <option value="Vidhyanagar">Vidhyanagar</option>
                          <option value="Maruti Solaris Mall">
                            Maruti Solaris Mall
                          </option>
                        </select>
                        {errors?.order_location?.type === "required" && (
                          <p className="pp">Please enter location.</p>
                        )}
                      </div>
                      <button className="login-btn" type="submit">
                        Submit
                      </button>
                    </form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Feedback
