import axios from "axios"
import config from "../../config"

const get = async (url) => {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "x-access-token": localStorage.getItem("token"),
    },
  }
  try {
    return await axios.get(`${config.apiUrl}${url}`, headers)
  } catch (e) {
    if (e.response.status === 500) {
      return ["Something went wrong please try again"]
    }
    return e.response.data.errors
  }
}

const post = async (url, data) => {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "x-access-token": localStorage.getItem("token"),
    },
  }
  try {
    return await axios.post(`${config.apiUrl}${url}`, data, headers)
  } catch (e) {
    if (e.response.status === 500) {
      return ["Something went wrong please try again"]
    }
    return e.response.data.errors
  }
}

const put = async (url, data) => {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "x-access-token": localStorage.getItem("token"),
    },
  }
  try {
    return await axios.put(`${config.apiUrl}${url}`, data, headers)
  } catch (e) {
    if (e.response.status === 500) {
      return ["Something went wrong please try again"]
    }
    return e.response.data.errors
  }
}

const postWithUpload = async (url, data, file) => {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "multipart/form-data",
      "x-access-token": localStorage.getItem("token"),
    },
  }
  var formData = new FormData()

  if (file) {
    formData.append("file", file)
  }
  const key = Object.keys(data)
  for (var i = 0; i < key.length; i++) {
    formData.append(key[i], data[key[i]])
  }
  try {
    return await axios.post(`${config.apiUrl}${url}`, formData, headers)
  } catch (e) {
    if (e.response.status === 500) {
      return ["Something went wrong please try again"]
    }
    return e.response.data.errors
  }
}

export default { get, post, put, postWithUpload }
