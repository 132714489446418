import { useEffect } from "react"
import { Circle } from "react-feather"
import { useDispatch, useSelector } from "react-redux"
import { outletState, getOutlets } from "../slices/OutletSlice"
import parse from "html-react-parser"
import { Col, Container, Row } from "react-bootstrap"
import SideStick from "./SideStick"

const OurOutlets = (value) => {
  const data = {
    id: value,
  }
  const dispatch = useDispatch()
  const { outletList } = useSelector(outletState)

  useEffect(() => {
    dispatch(getOutlets(data))
  }, [dispatch])

  return (
    <>
      <SideStick />
      <div className="cart">
        <p className="c1">Our Outlets</p>
      </div>
      <Container>
        <p className="outlet-para">
          The perfect combination of fun, food and friends- Poptos is a café cum
          restaurant that caters to its customers when their tummies roar with
          hunger. With a variety of offerings like perfectly baked pizzas,
          beverage options that quench thirst with a flavoursome satisfaction,
          burgers that you will get addicted to and a lot more, it is the
          ultimate hangout/dine out place for all your hunger pangs and boredom
          sessions.
        </p>

        <Row>
          {outletList?.outlets.map((outlet, index) => {
            const image =
              "https://www.poptos.com/assets/image/upload/" + outlet.image
            return (
              <>
                {/* <hr className="secondl" />
            <div className="oulet">
              <Circle className="circle2" />
              <div className="outlet-gallops">
                <img src={image} alt="" />
                <div>
                  <Circle className="circle " />
                  <Circle className="circle1 " />
                  <hr className="line1" />
                  <div className="and-olt">{outlets.name}</div>
                  <p className="description ">{parse(outlets.address)}</p>
                </div>
              </div>
            </div> */}
                {/* <div className="box-outlet"> */}
                <Col md={4} className="box-outlet">
                  <img src={image} alt="" />
                  <h4>{outlet.name}</h4>
                  <p>{parse(outlet.address)}</p>
                </Col>
                {/* </div> */}
              </>
            )
          })}
        </Row>
      </Container>
    </>
  )
}
export default OurOutlets
