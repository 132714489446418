import { Field, Formik } from "formik"
import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { useNavigate } from "react-router"
import Select from "react-select"
import * as Yup from "yup"
import SideStick from "./SideStick"

const options = [
  { value: "Anand", label: "Anand" },
  { value: "Vidhyanagar", label: "Vidhyanagar" },
  { value: "Maruti Sollaris Mall", label: "Maruti Sollaris Mall" },
]

const OrderSchema = Yup.object().shape({
  location: Yup.string().required("Please Select Location."),
  delivery_option: Yup.string().required("Please Select Type."),
})

const OurOutlets = () => {
  const [location, setLocation] = useState("Anand")
  const [delivery_option, setDeliveryOption] = useState("Home Delivery")
  const navigate = useNavigate()

  useEffect(() => {
    var data = localStorage.getItem("data")
    if (data) {
      const info = JSON.parse(data)
      if (info.location) {
        setLocation(info.location)
        setDeliveryOption(info.delivery_option)
      }
    }
  }, [])

  const handleFormSubmit = (values) => {
    const data = {
      ...values,
      location: location,
    }
    const info = JSON.stringify(data)
    localStorage.setItem("data", info)
    navigate("/menu")
  }
  return (
    <>
      <SideStick />
      <div className="cart">
        <p className="c1">Order Online</p>
      </div>
      <Container>
        <Formik
          initialValues={{
            location: location,
            delivery_option: delivery_option,
          }}
          enableReinitialize
          // validationSchema={OrderSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              <div>
                <h2 className="select-title mb-20">Select Outlet</h2>
              </div>
              <div>
                <Select
                  name="location"
                  className="select"
                  options={options}
                  placeholder={location}
                  onChange={(value) => {
                    setLocation(value.value)
                  }}
                  onBlur={handleBlur}
                />
                {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.location}
                        </span> */}
                <div className="mt-20">
                  <div className="btn-radio">
                    <Field
                      type="radio"
                      name="delivery_option"
                      value="Home Delivery"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="delivery">Delivery</span>
                  </div>
                  <div className="btn-radio">
                    <Field
                      type="radio"
                      name="delivery_option"
                      value="Take Away"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="delivery">Take-away</span>
                  </div>
                  <div className="btn-radio">
                    <Field
                      type="radio"
                      name="delivery_option"
                      value="Dine In"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="delivery">Dine-in</span>
                  </div>
                </div>
                {/* <span
                  className="error"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  {errors.delivery_option}
                </span> */}
              </div>
              <button className="con-btn" type="submit">
                Continue Order
              </button>
            </form>
          )}
        </Formik>
      </Container>
    </>
  )
}
export default OurOutlets
