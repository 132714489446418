import { Card, Container } from "react-bootstrap"
import { useNavigate } from "react-router"

const OrderFail = () => {
  const navigate = useNavigate()
  const handelSubmit = () => {
    navigate("/")
  }
  return (
    <>
      <div className="cart">
        <p className="c1">Thank you</p>
      </div>
      <Container>
        <Card className="m-40">
          <img
            src="/assets/images/cancel.png"
            className="success"
            alt="Failure"
          />
          <h3 className="success-title">
            <strong>Order Cancelled</strong>
          </h3>
          <button
            className="con-btn success-btn"
            onClick={() => {
              handelSubmit()
            }}
          >
            Back to home
          </button>
        </Card>
      </Container>
    </>
  )
}

export default OrderFail
