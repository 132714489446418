import { createSlice } from "@reduxjs/toolkit";
import clientService from "./services/service";

export const outletInitialState = {
    outletList: {
        outlets: []
    }
}
export const outletSlice = createSlice({
    name: "outlets",
    initialState: outletInitialState,
    reducers: {
        setOutletResponse: (state, action) => {
            state.outletList = action.payload;
        },
    }
})


export const getOutlets = () => async (dispatch) => {
    const response = await clientService.get("outlet/content");
    if (response.data) {
        dispatch(setOutletResponse(response.data.data))
    } else {
        // dispatch(setMenuResponse(response.message)) 
    }

}


export const { setOutletResponse } = outletSlice.actions

export const outletState = (state) => {
    return state.outlets
}

export default outletSlice.reducer;