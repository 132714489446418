import { Card, Col, Container, Row } from "react-bootstrap"
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";


const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {
        alert(JSON.stringify(data));
    };

    return (
        <>
            <div className="cart mb-3">
                <p className="c1">Welcome To Poptos</p>
            </div>
            <Container>
                <Row>
                    <Col md={8}>
                        <Card className="card5 mb-5">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <h4 className="carddd">Forgot Password</h4>
                                    <h5 className="enter-email">Please enter your Email so we can send you an email to reset your password.</h5>
                                </div>
                                <div><input className="login-email" {...register("email", { required: true })} type="email" placeholder="Email address" />{errors?.email?.type === "required" && <p className="pp">Please enter Email id.</p>}</div>
                                <div>
                                    <button className="login-btn" type="submit">Submit</button>
                                </div>
                            </form>
                        </Card>
                    </Col>
                    <Col>
                        <img className="gif3 mb-3" src="https://www.poptos.com/assets/user/images/popman-gif-log-in.gif" alt="gif" />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
const rootElement = document.getElementById("root");
ReactDOM.render(<ForgotPassword />, rootElement);
export default ForgotPassword