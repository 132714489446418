import { Link } from "react-router-dom"
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { cartState } from "../slices/CartSlice"
import { useSelector } from "react-redux"
library.add(faFacebookF, faInstagram)

const Footer = () => {

  const { cartItems } = useSelector(cartState)
  return (
    <footer className="ts-footer solid-bg-two cart_active">
      {/* {cartItems.length}
      {cartItems.length >= 1 ? 
      <div className="cart-count">
        <Link to="/cart">{cartItems.length}</Link>
      </div>:null} */}
      <div className="container pt-5">
        <div className="footer-logo-area text-center"></div>

        <div className="row mb-5">
          <div className="col-lg-4 col-md-6">
            <div className="footer-widget footer-three-widget">
              <h3 className="widget-title">Quick Links</h3>
              <div className="textwidget">
                <ul id="footer-menu" className="footer-menu">
                  <li
                    id="menu-item-1309"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1309 nav-item"
                  >
                    <Link to="/our-story" className="nav-link p-0">
                      Our Story
                    </Link>
                  </li>
                  <li
                    id="menu-item-1308"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1308 nav-item"
                  >
                    <Link to="/our-outlets" className="nav-link p-0">
                      Store Locator
                    </Link>
                  </li>
                  <li
                    id="menu-item-1305"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1305 nav-item"
                  >
                    <Link to="/feedback" className="nav-link p-0">
                      Feedback
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="footer-widget footer-two-widget">
              <h3 className="widget-title">Legal</h3>
              <div className="textwidget">
                <ul id="footer-menu" className="footer-menu">
                  <li
                    id="menu-item-1309"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1309 nav-item"
                  >
                    <Link to="/privacy-policy" className="nav-link p-0">
                      Privacy Policy
                    </Link>
                  </li>
                  <li
                    id="menu-item-1308"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1308 nav-item"
                  >
                    <Link to="/terms_and_condition" className="nav-link p-0">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li
                    id="menu-item-1305"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1305 nav-item"
                  >
                    <Link to="/refund_policy" className="nav-link p-0">
                      Refund &amp; Cancellation Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 copyright p-0">
            <div className="footer-widget footer-four-widget">
              <h3 className="widget-title ml-10">Social Media</h3>
              <div className="footer-social ml-10">
                <ul className="p-0">
                  <a href="https://www.facebook.com/OfficialPOPTOS/" target="_blank">
                    <FontAwesomeIcon
                      className="facebook fa-lg"
                      icon={["fab", "facebook-f"]}
                    />
                  </a>
                  <a href="https://www.instagram.com/officialpoptos/?hl=en" target="_blank">
                    <FontAwesomeIcon
                      className="instagram fa-lg"
                      icon={faInstagram}
                    />{" "}
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="line-footer" />

        <div className="footer-bar"> </div>
        <div className="row copyright">
          <div className="col-lg-12 col-md-12 align-self-center text-center">
            <div className="copyright-text">
              © 2021, Poptos. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
