import { Card, Col, Container, Row } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import parse from "html-react-parser"
import { CMSState, getStory } from "../slices/CmSlice"
import SideStick from "./SideStick"

const OurStory = () => {
  const dispatch = useDispatch()
  const { Story } = useSelector(CMSState)
  useEffect(() => {
    dispatch(getStory())
  }, [dispatch])

  return (
    <>
      <SideStick />
      <div className="cart mb-5">
        <p className="c1">About</p>
      </div>
      <Container>
        <Row className="common">
          <Col md={4}>
            {Story.sec1_image1 ? (
              <img
                className="slush mb-5"
                src={
                  "https://www.poptos.com/assets/image/upload/" +
                  Story.sec1_image1
                }
                alt=""
              />
            ) : null}
          </Col>
          <Col md={4}>
            <Card className="abt-crd mb-5">
              <Row>
                <Col>
                  <h2 className="abt-ttle">WHO ARE WE</h2>
                  {Story.title_sec1 ? (
                    <h2 className="abt-ttle2 mb-3">
                      {parse(Story.title_sec1)}
                    </h2>
                  ) : null}
                  {Story.content_sec1 ? (
                    <p className="normal mt-3">{parse(Story.content_sec1)}</p>
                  ) : null}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            {Story.sec1_image2 ? (
              <img
                className="shake"
                src={
                  "https://www.poptos.com/assets/image/upload/" +
                  Story.sec1_image2
                }
                alt=""
              />
            ) : null}
          </Col>
        </Row>
        {/* <Container className="outlet-bg mt-5 mb-5"> */}
        {/* <Row className="common">
          <div className="abt2">
            {Story.sec2_image ? (
              <img
                className="abt-burger"
                src={
                  "https://www.poptos.com/assets/image/upload/" +
                  Story.sec2_image
                }
                alt=""
              />
            ) : null}
            <Col>
              {Story.sec2_icon2 ? (
                <img
                  className="leaf "
                  src={
                    "https://www.poptos.com/assets/image/upload/" +
                    Story.sec2_icon2
                  }
                  alt=""
                />
              ) : null}
            </Col>
            <Col>
              {Story.sec2_text2 ? (
                <h3 className="qua-leaf">{parse(Story.sec2_text2)}</h3>
              ) : null}
            </Col>
            <Col>
              {Story.sec2_icon3 ? (
                <img
                  className="scooty"
                  src={
                    "https://www.poptos.com/assets/image/upload/" +
                    Story.sec2_icon3
                  }
                  alt=""
                />
              ) : null}
            </Col>
            <Col>
              {Story.sec2_text3 ? (
                <h3 className="scoo">{parse(Story.sec2_text3)}</h3>
              ) : null}
            </Col>
            <Col>
              {Story.sec2_icon4 ? (
                <img
                  className="cheaf"
                  src={
                    "https://www.poptos.com/assets/image/upload/" +
                    Story.sec2_icon4
                  }
                  alt=""
                />
              ) : null}
            </Col>
            <Col>
              {Story.sec2_text4 ? (
                <h3 className="great">{parse(Story.sec2_text4)}</h3>
              ) : null}
            </Col>
            <Col>
              {Story.sec2_icon1 ? (
                <img
                  className="affordable"
                  src={
                    "https://www.poptos.com/assets/image/upload/" +
                    Story.sec2_icon1
                  }
                  alt=""
                />
              ) : null}
            </Col>
            <Col>
              {Story.sec2_text1 ? (
                <h3 className="prices mb-5">{parse(Story.sec2_text1)}</h3>
              ) : null}
            </Col>
          </div>
        </Row> */}
      </Container>
      {/* </Container> */}
    </>
  )
}
export default OurStory
