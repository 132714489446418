import { Carousel, Col, Container, Row } from "react-bootstrap"
import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getHome, CMSState } from "../slices/CmSlice"
import { Link } from "react-router-dom"
import parse from "html-react-parser"
import SideStick from "./SideStick"

const HomePage = () => {
  const dispatch = useDispatch()
  const { Home } = useSelector(CMSState)
  useEffect(() => {
    dispatch(getHome())
  }, [])

  return (
    <>
      <SideStick />
      <div className="slider mb-3">
        <Carousel controls={false} interval={3000} pause={true}>
          {Home?.sliders?.map((sliders) => {
            return (
              <Carousel.Item>
                <Container>
                  <Row>
                    <Col xs={6} className={"d-flex justify-items-c"}>
                      <div className="new-in-menu">New In MENU _______</div>
                      {sliders.title ? (
                        <h3 className="item-name">{sliders.title}</h3>
                      ) : null}
                    </Col>
                    <Col xs={6}>
                      {sliders.image ? (
                        <img
                          className="max-100"
                          src={
                            "https://www.poptos.com/assets/image/upload/" +
                            sliders.image
                          }
                          alt="First slide"
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
            )
          })}
        </Carousel>
      </div>
      <Container>
        {Home?.homeDetails?.map((homeDetails) => {
          return (
            <div>
              <Row>
                <Col md={6}>
                  <div className="welcome mt-5">WELCOME</div>
                  {homeDetails.welcome_title ? (
                    <h2 className="welcome-detail">
                      {homeDetails.welcome_title}
                    </h2>
                  ) : null}
                  {homeDetails.welcome_content ? (
                    <div className="welcome-description mt-2 mb-1">
                      {homeDetails.welcome_content}
                    </div>
                  ) : null}
                  <Link to="/our-story" className="onl">
                    <button className="about mb-5 mt-10">About us</button>
                  </Link>
                </Col>
                <Col md={6}>
                  {homeDetails.welcome_image ? (
                    <img
                      className="max-100"
                      src={
                        "https://www.poptos.com/assets/image/upload/" +
                        homeDetails.welcome_image
                      }
                      alt=""
                    />
                  ) : null}
                </Col>
              </Row>
            </div>
          )
        })}
      </Container>

      <div className="del-bg">
        <div className="delivery mt-5">
          <Container>
            <Row>
              <Col md={8}>
                {Home?.homeDetails?.map((homeDetails) => {
                  return (
                    <>
                      <Col md={6}>
                        <h2 className="del">DELIVERY</h2>
                        {homeDetails.delivery_title ? (
                          <h2 className="del-sen">
                            {parse(homeDetails.delivery_title)}
                          </h2>
                        ) : null}
                        {homeDetails.delivery_content ? (
                          <p className="del-para">
                            {parse(homeDetails.delivery_content)}
                          </p>
                        ) : null}
                        <Link to="/online-order" className="onl">
                          <button className="order mb-5 mt-10">
                            Order online
                          </button>
                        </Link>
                      </Col>
                      <Col md={6}>
                        <div className="scooter">
                          {homeDetails.delivery_image ? (
                            <img
                              className="mascout"
                              src={
                                "https://www.poptos.com/assets/image/upload/" +
                                homeDetails.delivery_image
                              }
                              alt=""
                            />
                          ) : null}
                        </div>
                      </Col>
                    </>
                  )
                })}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default HomePage
