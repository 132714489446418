import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import parse from "html-react-parser"
import { CMSState, getPrivacy } from "../slices/CmSlice"
import SideStick from "./SideStick"

const PrivacyPolicyy = () => {
  const dispatch = useDispatch()
  const { privacyPolicy } = useSelector(CMSState)
  useEffect(() => {
    dispatch(getPrivacy())
  }, [dispatch])

  return (
    <>
      <SideStick />
      <div className="banner">
        <p className="c1">Privacy Policy</p>
      </div>
      {privacyPolicy.content ? (
        <p className="normal2">{parse(privacyPolicy.content)}</p>
      ) : null}
    </>
  )
}
export default PrivacyPolicyy
