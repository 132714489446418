import { createSlice } from "@reduxjs/toolkit";
import clientService from "./services/service";

export const CMSInitialState = {
    privacyPolicy: {},
    termsCondition: {},
    Story: {},
    refundPolicy: {},
    Home:{}

}

export const CMSSlice = createSlice({
    name: "cms",
    initialState: CMSInitialState,
    reducers: {
        setPrivacyResponse: (state, action) => {
            state.privacyPolicy = action.payload;
        },
        setTermsResponse: (state, action) => {
            state.termsCondition = action.payload;
        },
        setStory: (state, action) => {
            state.Story = action.payload;
        },
        setRefundPolicy: (state, action) => {
            state.refundPolicy = action.payload;
        },
        setHomeResponse: (state, action) => {
            state.Home = action.payload;
        },

    },
});
export const getPrivacy = () => async (dispatch) => {
    const response = await clientService.get("privacy/policy");
    if (response.data) {
        dispatch(setPrivacyResponse(response.data.data))
    } else {
    }
}

export const getTerms = () => async (dispatch) => {
    const response = await clientService.get("terms/condition");
    
    if (response.data) {
        dispatch(setTermsResponse(response.data.data))
    } else {

    }
}

export const getStory = () => async (dispatch) => {
    const response = await clientService.get("our/story");
    if (response.data) {
        dispatch(setStory(response.data.data))
    } else {
    }
}

export const getRefundPolicy = () => async (dispatch) => {
    const response = await clientService.get("refund/policy");
    if (response.data) {
        dispatch(setRefundPolicy(response.data.data))
    } else {
    }
}

export const getHome = () => async (dispatch) => {
    const response = await clientService.get("home");
    if (response.data) {
        dispatch(setHomeResponse(response.data.data))
    } else {
    }
}

export const { setPrivacyResponse, setTermsResponse, setRefundPolicy, setStory, setHomeResponse } = CMSSlice.actions

export const CMSState = (state) => {
    
    return state.cms;
}

export default CMSSlice.reducer;