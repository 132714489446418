import { faCartPlus, faMapPin } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from "react-redux"
import { cartState } from "../slices/CartSlice"

const SideStick = () => {
  const { cartItems } = useSelector(cartState)
  return (
    <>
      {cartItems.length >= 1 ? (
        <div className="sticky menu1 shop-cart1 s-shop-cart position-relative d-sm-inline-block">
          <div className="mt-13">
            <div className="">
              <a
                className="cart-contents a-decor"
                href="/cart"
                title="View your shopping cart"
              >
                <FontAwesomeIcon icon={faCartPlus} className="font-white" />
                <sup className="cart-stick backGround-white margin-2">
                  {cartItems.length}
                </sup>
              </a>
            </div>
          </div>
        </div>
      ) : null}
      {/* <a href="/online-order">
        <div className="sticky1 menu2 shop-cart1 s-shop-cart position-relative d-none d-sm-inline-block d-flex justify-content-center text-center">
          <FontAwesomeIcon
            icon={faMapPin}
            className="fa fa-map-marker mt-3 mb-2"
            aria-hidden="true"
            style={{ fontSize: "22px !important", color: "#fff !important " }}
          />
        </div>
      </a>{" "} */}
    </>
  )
}

export default SideStick
