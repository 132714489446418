import produce from "immer"
import { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import config from "../config"
import { useSelector, useDispatch } from "react-redux"
import { getToppings, menuState, setToppingResponse } from "../slices/MenuSlice"
import StickyBox from "react-sticky-box"
import { cartState, setCartItems } from "../slices/CartSlice"

const MenuType = {
  "Pop Pizza": "Choose Your Crust",
  "Pop Funn Pizza": "Choose Your Crust",
  "Pop Submarine Sandwich": "Choose Your Bread",
  "Pop Bites": "Choose Your Dip / Size",
  "Pop Desserts": "Choose Option",
  "Pop Steam": "Choose Option",
  "Pop Slush": "Choose Option",
  "Pop Burger": "Choose Your Bun",
  "Pop Iced": "Choose Option",
}
const ItemDetail = ({ product, handleClose }) => {
  const dispatch = useDispatch()
  const [variations, setVariations] = useState([])
  const [totalToppings, setTotalToppings] = useState(0)
  const [selectedToppings, setSelectedToppings] = useState([])
  const [selectedExtraItem, setSelectedExtraItem] = useState([])
  const [crust, setCrust] = useState("")
  const [crustIndex, setCrustIndex] = useState(-1)
  const [qty, setQty] = useState(1)
  const [size, setSize] = useState("")
  const { toppings, extra_items, loader } = useSelector(menuState)
  const { cartItems } = useSelector(cartState)

  useEffect(() => {
    let defaultVariation = []
    dispatch(setToppingResponse({}))
    if (product.variations) {
      const variations = []
      for (var i = 0; i < product.variations.length; i++) {
        var varPosition = -1
        const nameInfo = product.variations[i].variation.split(",")
        for (var j = 0; j < variations.length; j++) {
          if (variations[j].name === nameInfo[0]) {
            varPosition = j
          }
        }
        if (varPosition === -1) {
          if (nameInfo[1]) {
            variations.push({
              name: nameInfo[0],
              size: [
                {
                  ...product.variations[i],
                  name: nameInfo[1],
                },
              ],
            })
          } else {
            variations.push({
              name: nameInfo[0],
              ...product.variations[i],
              size: [],
            })
          }
        } else {
          variations[varPosition].size.push({
            ...product.variations[i],
            name: nameInfo[1],
          })
        }
      }

      if (product?.default_variation?.length >= 1) {
        defaultVariation = product.default_variation[0].variation.split(",")
        setCrust(defaultVariation[0])
        setSize(defaultVariation[1])
        for (var k = 0; k < variations.length; k++) {
          if (variations[k].name === defaultVariation[0]) {
            setCrustIndex(k)
          }
        }
      } else {
        setCrustIndex(0)
        setCrust(variations[0]?.name)
        setSize(variations[0]?.size[0].name)
      }
      setVariations(variations)
    }
  }, [product.id])

  useEffect(() => {
    if (!loader) {
      var toppingsName = []
      var defaultCount = 0
      for (var i = 0; i < toppings.length; i++) {
        if (toppings[i].default_val == 1) {
          defaultCount++
          toppingsName.push(toppings[i].extra_product_name)
        }
      }
      setSelectedToppings(toppingsName)
      setTotalToppings(defaultCount)
    }
  }, [loader])
  useEffect(() => {
    // if (size) {
      dispatch(
        getToppings({
          product_id: product.id,
          size: size,
        })
      )
    // }
  }, [size])

  const removeQty = () => {
    if (qty == 1) {
      return
    }
    setQty(qty - 1)
  }
  const addQty = () => {
    if (qty == 4) {
      return
    }
    setQty(qty + 1)
  }
  const getPrice = () => {
    var price = Number(product.regular_price)
    if (crust && size) {
      for (var k = 0; k < variations.length; k++) {
        if (variations[k].name === crust) {
          if (variations[k].size.length > 0) {
            for (var i = 0; i < variations[k].size.length; i++) {
              if (variations[k].size[i].name === size) {
                price = Number(variations[k].size[i].price)
              }
            }
          } else {
            price = Number(variations[k].price)
          }
        }
      }
    } else if (crust) {
      for (var k = 0; k < variations.length; k++) {
        if (variations[k].name === crust) {
          price = Number(variations[k].price)
        }
      }
    }
    if (selectedToppings.length > totalToppings) {
      price +=
        (selectedToppings.length - totalToppings) * toppings[0].extra_price
    }

    if (extra_items.length >= 1) {
      for (var i = 0; i < extra_items.length; i++) {
        if (
          selectedExtraItem.indexOf(extra_items[i].extra_product_name) !== -1
        ) {
          price += Number(extra_items[i].extra_price)
        }
      }
    }
    return price
  }
  const addToCart = () => {
    const cartItem = {
      qty,
      price: getPrice(),
      selectedToppings: selectedToppings,
      extra_items: extra_items,
      size: size,
      crust: crust,
      product: product,
    }
    const updateCartItems = [...cartItems, cartItem]
    dispatch(setCartItems(updateCartItems))
    handleClose()
  }
  return (
    <div className="item-detail-container">
      <div className="item-detail-body">
        <div className="text-center mt-20">
          <img className="pizza" src={config.imageUrl + product.image} alt="" />
        </div>
        <div className="prod-desc">{product.description.toLowerCase()}</div>
        {variations.length > 0 ? (
          <>
            <h4 className="product_title">{MenuType[product.categories]}</h4>
            <div className="crust-btn">
              <Col>
                {variations.map((item, index) => {
                  return (
                    <button
                      key={`variations${item.name}`}
                      className={
                        crust === item.name
                          ? "crust-type-butter active"
                          : "crust-type-butter"
                      }
                      onClick={() => {
                        setCrustIndex(index)
                        setSize(item.size[0]?.name)
                        setCrust(item.name)
                      }}
                    >
                      {item.name}
                    </button>
                  )
                })}
              </Col>
            </div>
          </>
        ) : null}
        {variations[crustIndex]?.size.length > 0 ? (
          <>
            <hr className="line bold" />
            <div className="crust-btn">
              <Col>
                {variations[crustIndex]?.size?.map((item, index) => {
                  return (
                    <button
                      key={`variations_2${item.name}`}
                      className={
                        size == item.name
                          ? "crust-type-butter active"
                          : "crust-type-butter"
                      }
                      onClick={() => setSize(item.name)}
                    >
                      {item.name}
                    </button>
                  )
                })}
              </Col>
            </div>
          </>
        ) : null}
        {toppings.length > 0 ? (
          <div className="crust-btn">
            <h4 className="product_title mt-5">Toppings</h4>
            {toppings.map((topping) => {
              return (
                <button
                  key={`topping${topping.extra_product_name}`}
                  className={
                    selectedToppings.indexOf(topping.extra_product_name) === -1
                      ? "crust-type-butter"
                      : "crust-type-butter active"
                  }
                  onClick={() => {
                    var v = [...selectedToppings]
                    if (v.indexOf(topping.extra_product_name) === -1) {
                      v.push(topping.extra_product_name)
                    } else {
                      v.splice(v.indexOf(topping.extra_product_name), 1)
                    }
                    setSelectedToppings(v)
                  }}
                >
                  {topping.extra_product_name}
                </button>
              )
            })}
            <p className="mb-0">
              * Marked toppings are current toppings in pizza.
            </p>
            <p className="mb-0">* Customer can replace any two toppings.</p>
            <p className="mb-0">* After any two toppings change if select third topping then it will charge extra.<br/>(This rule not affected in some selected pizzas.) </p>
          </div>
        ) : null}
        {extra_items.length >= 1 ? (
          <div className="crust-btn">
            <h4 className="product_title mt-5">Extra Items</h4>

            {extra_items.map((extra_item) => {
              return (
                <button
                  key={`key-item${extra_items.id}`}
                  className={
                    selectedExtraItem.indexOf(extra_item.extra_product_name) ===
                    -1
                      ? "crust-type-butter"
                      : "crust-type-butter active"
                  }
                  onClick={() => {
                    var v = [...selectedExtraItem]
                    if (v.indexOf(extra_item.extra_product_name) === -1) {
                      v.push(extra_item.extra_product_name)
                    } else {
                      v.splice(v.indexOf(extra_item.extra_product_name), 1)
                    }
                    setSelectedExtraItem(v)
                  }}
                >
                  {extra_item.extra_product_name}
                </button>
              )
            })}
          </div>
        ) : null}
      </div>
      <div className="item-detail-footer">
        <button className="price">₹ {getPrice() * qty}</button>
        <button onClick={removeQty} className="btn-minus">
          -
        </button>
        <button className="btn-qty">{qty}</button>
        <button onClick={addQty} className="btn-plus">
          +
        </button>
        <button className="addtocart" onClick={addToCart}>
          Add to Cart
        </button>
      </div>
    </div>
  )
}
export default ItemDetail
