import "./App.css"
import { store, persistor } from "./store"
import { Routes, Route, Link } from "react-router-dom"
import Home from "./pages/Home"
import "bootstrap/dist/css/bootstrap.min.css"
import Header from "./components/Header"
import Footer from "./components/Footer"
import $ from "jquery"
import Menu from "./pages/Menu"
import Cart from "./pages/Cart"
import { Provider } from "react-redux"
import Login from "./pages/Login"
import OurOutlets from "./pages/OurOutlets"
import OnlineOrder from "./pages/OnlineOrder"
import OurStory from "./pages/OurStory"
import Feedback from "./pages/Feedback"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import RefundPolicy from "./pages/RefundPolicy"
import TermsAndCondition from "./pages/TermsandCondition"
import Checkout from "./pages/Checkout"
import ForgotPassword from "./pages/ForgotPassword"
import SignUp from "./pages/SignUp"
import { PersistGate } from "reduxjs-toolkit-persist/integration/react"
import { Suspense } from "react"
import OrderSuccess from "./pages/OrderSuccess"
import OrderFail from "./pages/OrderFail"
import { positions, Provider as AlertProvider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"

window.jQuery = $

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
}

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Header />
          <Suspense
            fallback={
              <div id="loader">
                <div className="loader"></div>{" "}
              </div>
            }
          >
            <AlertProvider template={AlertTemplate} {...options}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/login" element={<Login />} />
                <Route path="/our-outlets" element={<OurOutlets />} />
                <Route path="/online-order" element={<OnlineOrder />} />
                <Route path="/our-story" element={<OurStory />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms_and_condition"
                  element={<TermsAndCondition />}
                />
                <Route path="/refund_policy" element={<RefundPolicy />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/order/success" element={<OrderSuccess />} />
                <Route path="/order/failure" element={<OrderFail />} />
              </Routes>
            </AlertProvider>
          </Suspense>
          <Footer />
        </PersistGate>
      </Provider>
    </>
  )
}

export default App
