import { Card, Row, Col, Container } from "react-bootstrap"
import { Trash2 } from "react-feather"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { cartState, setCartItems } from "../slices/CartSlice"
import { useDispatch, useSelector } from "react-redux"
import config from "../config"
import { getMenu } from "../slices/MenuSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCartPlus, faMapPin } from "@fortawesome/free-solid-svg-icons"
import SideStick from "./SideStick"

const ButtonDecrement = (props) => {
  return (
    <button
      className="minus"
      style={{ marginLeft: ".5rem" }}
      onClick={props.onClickFunc}
    >
      -
    </button>
  )
}
const ButtonIncrement = (props) => {
  return (
    <button
      className="plus"
      style={{ marginLeft: ".5rem" }}
      onClick={props.onClickFunc}
    >
      +
    </button>
  )
}
const Display = (props) => {
  return <label style={{ marginLeft: ".5rem" }}>{props.message}</label>
}

const Cart = () => {
  const { cartItems } = useSelector(cartState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)
  const [gst, setGst] = useState(0)

  useEffect(() => {
    const locationInfo = localStorage.getItem("data")
    if (!locationInfo) {
      navigate("/online-order")
    }
  }, [])

  useEffect(() => {
    var totalPrice = 0
    for (var i = 0; i < cartItems.length; i++) {
      totalPrice += cartItems[i].price * cartItems[i].qty
    }
    var cgst = totalPrice * 0.025
    setGst(Number(cgst.toFixed(2)))
    setTotal(totalPrice)
  }, [cartItems])
  const removeItem = (index) => {
    const items = [...cartItems]
    items.splice(index, 1)
    dispatch(setCartItems(items))
  }
  const updateQuantity = (index, value) => {
    const items = [...cartItems]
    if (items[index].qty === 1 && value === -1) {
      return
    }
    items[index] = {
      ...items[index],
      qty: items[index].qty + value,
    }
    dispatch(setCartItems(items))
  }

  const handelSubmit = () => {
    navigate("/menu")
  }
  return (
    <>
      <SideStick />
      <div className="cart mb-5">
        <p className="c1">Cart</p>
      </div>
      <Container>
        {cartItems.length > 0 ? (
          <Row>
            <Col md={8}>
              <Card className="card">
                <div>
                  <h4 className="cardd">Cart (items)</h4>
                </div>
                {cartItems.map((cartItem, index) => {
                  return (
                    <>
                      <Row key={"cart-item" + index}>
                        <Col sm={12} md={4}>
                          <img
                            className="img1"
                            src={config.imageUrl + cartItem.product.image}
                            alt=""
                          />
                        </Col>
                        <Col sm={12} md={8}>
                          <div className="item-title">
                            {cartItem.product.product_name} - {cartItem.crust},{" "}
                            {cartItem.size}
                          </div>
                          <div className="topp mb-3">
                            {cartItem.selectedToppings.join(", ")}
                          </div>
                          <div className="pric">
                            Price: ₹{(cartItem.price * cartItem.qty).toFixed(2)}
                          </div>
                          <div className="qua">
                            <p>
                              Quantity :
                              <button
                                onClick={() => updateQuantity(index, -1)}
                                className="btn-minus"
                              >
                                -
                              </button>
                              <button className="btn-qty">
                                {cartItem.qty}
                              </button>
                              <button
                                onClick={() => updateQuantity(index, 1)}
                                className="btn-plus"
                              >
                                +
                              </button>
                            </p>
                          </div>
                          <span
                            onClick={() => removeItem(index)}
                            className="rem-item"
                          >
                            <Trash2 />
                            Remove item
                          </span>
                        </Col>
                      </Row>
                      <hr className="line-black" />
                    </>
                  )
                })}
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card mt-10 mb-10">
                <div className="card-2-title">Cart Totals</div>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col>
                        <span className="subtotal">Subtotal</span>
                      </Col>
                      <Col className="text-right">₹{total.toFixed(2)}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="subtotal">CGST @ 2.5%</span>
                      </Col>
                      <Col className="text-right">₹{gst.toFixed(2)}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="subtotal">SGST @ 2.5%</span>
                      </Col>
                      <Col className="text-right">₹{gst.toFixed(2)}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="subtotal">Final Total</span>
                      </Col>
                      <Col className="text-right">
                        ₹{(total + gst + gst).toFixed(2)}
                      </Col>
                    </Row>
                    {/* <ul>
                                        <span className="subtotal">Discount<bdi>₹0</bdi></span>
                                    </ul> */}
                  </Col>
                </Row>
                <hr className="line-black" />
                <Link className="updatecart" to="/checkout">
                  Proceed to checkout
                </Link>
              </Card>
              {/* <Card className="card mt-5 mb-5">
                            <input className="applycpn" placeholder="Add a discount coupon code(optional)" />
                            <button className="updatecart">Apply</button>
                        </Card> */}
            </Col>
          </Row>
        ) : (
          <Card className="m-40">
            <h3 className="success-title">
              <strong>
                Your cart is empty <br />
                <br /> <h5>Add Something from the Menu. </h5>
              </strong>
            </h3>
            <button
              className="con-btn success-btn"
              onClick={() => {
                handelSubmit()
              }}
            >
              ORDER NOW!
            </button>
          </Card>
        )}
      </Container>
    </>
  )
}
export default Cart
