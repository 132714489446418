import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import parse from "html-react-parser"
import { CMSState, getTerms } from "../slices/CmSlice"
import SideStick from "./SideStick"

const TermsAndCondition = () => {
  const dispatch = useDispatch()
  const { termsCondition } = useSelector(CMSState)
  useEffect(() => {
    dispatch(getTerms())
  }, [dispatch])

  return (
    <>
      <SideStick />
      <div className="banner">
        <p className="c1">Terms And Condition</p>
      </div>
      {termsCondition.content ? (
        <p className="normal2">{parse(termsCondition?.content)}</p>
      ) : null}
    </>
  )
}
export default TermsAndCondition
