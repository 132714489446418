import { Card, Col, Container, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useState } from "react"
import { postLogin } from "../slices/AuthSlice"
import { useDispatch } from "react-redux"

const SignUp = () => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const onSubmit = (data) => {
    const loginData = JSON.stringify(data)
    alert(loginData)
    dispatch(postLogin(JSON.parse(loginData)))
  }
  const [date, setDate] = useState(new Date())
  const handleCalendarClose = () => console.log("Calendar closed")
  const handleCalendarOpen = () => console.log("Calendar opened")
  return (
    <>
      <div className="cart mb-3">
        <p className="c1">Welcome To Poptos</p>
      </div>
      <Container>
        <Row>
          <Col md={8}>
            <Card className="card6 mb-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <h4 className="carddd">Sign Up</h4>
                </div>
                <Row>
                  <Col md={8}>
                    <input
                      type="tel"
                      {...register("phone", { required: true, maxLength: 11 })}
                      className="feedbacks"
                      name="phone"
                      id="phone"
                      placeholder="Mobile No."
                    />
                    {errors?.phone?.type === "required" && (
                      <p className="pp">Please enter Mobile number.</p>
                    )}
                    <div>
                      <input
                        className="feedbacks"
                        {...register("email", { required: true })}
                        type="email"
                        placeholder="Email address"
                      />
                      {errors?.email?.type === "required" && (
                        <p className="pp">Please enter Email id.</p>
                      )}
                    </div>

                    <input
                      type="text"
                      className="feedbacks"
                      {...register("firstName", {
                        required: true,
                        maxLength: 20,
                        pattern: /^[A-Za-z]+$/i,
                      })}
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                    />
                    {errors?.firstName?.type === "required" && (
                      <p className="pp">Please enter your First Name</p>
                    )}
                    {errors?.firstName?.type === "maxLength" && (
                      <p className="pp">
                        First name cannot exceed 20 characters
                      </p>
                    )}
                    {errors?.firstName?.type === "pattern" && (
                      <p className="pp">Alphabetical characters only</p>
                    )}
                    <input
                      type="text"
                      className="feedbacks"
                      {...register("lastName", {
                        required: true,
                        maxLength: 20,
                        pattern: /^[A-Za-z]+$/i,
                      })}
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                    />
                    {errors?.lastName?.type === "required" && (
                      <p className="pp">Please enter your Last Name</p>
                    )}
                    {errors?.lastName?.type === "maxLength" && (
                      <p className="pp">
                        First name cannot exceed 20 characters
                      </p>
                    )}
                    {errors?.lastName?.type === "pattern" && (
                      <p className="pp">Alphabetical characters only</p>
                    )}
                    <DatePicker
                      {...register("selected")}
                      name="selected"
                      valueName="selected"
                      className="eml"
                      placeholder="Birth Date"
                      selected={date}
                      onChange={([selected]) => setDate(selected)}
                      dateFormat="dd/MM/yyyy"
                    />
                    {/* {errors?.date?.type === "required" && (
                      <p className="pp">The Date of birth field is required.</p>
                    )} */}
                    <div>
                      <input
                        className="login-password"
                        {...register("password", { required: true })}
                        type="password"
                        placeholder="Password"
                      />
                      {errors?.password?.type === "required" && (
                        <p className="pp">Please enter Password</p>
                      )}
                    </div>
                    <div>
                      <input
                        className="login-password"
                        {...register("confirmPassword", { required: true })}
                        type="password"
                        placeholder="Confirm Password"
                      />
                      {errors?.confirmPassword?.type === "required" && (
                        <p className="pp">Please confirm Password</p>
                      )}
                    </div>
                    <div>
                      <button className="login-btn" type="submit">
                        Sign Up
                      </button>
                    </div>
                    <div className="new-user">
                      Already Sign Up?
                      <a href="/login" className="sign-up">
                        Login Now
                      </a>
                    </div>
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
          <Col>
            <img
              className="gif1"
              src="https://www.poptos.com/assets/user/images/popman-gif-log-in.gif"
              alt="gif"
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
// const rootElement = document.getElementById("root")
// ReactDOM.render(<SignUp />, rootElement)
export default SignUp
